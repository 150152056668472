.rbc-calendar {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rbc-time-view {
  border: none;
}

.rbc-timeslot-group {
  border-bottom: none;
  position: relative;
  min-height: 76px;
}

.rbc-day-slot:last-child .rbc-events-container {
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin-right: 0 !important;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #dbdbdb;
}

.rbc-day-slot .rbc-timeslot-group .rbc-time-slot:nth-child(even) {
  border-top: 1px dashed #dbdbdb;
}

.rbc-time-slot {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-time-header-content {
  border-left: 1px #dbdbdb solid;
}

.rbc-time-view .rbc-row {
  border-top: 1px #dbdbdb solid;
}

.rbc-time-header-content .rbc-row-content {
  display: none;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px #dbdbdb solid;
}

.rbc-btn-group > button {
  color: #1f4b81;
  border-color: #1f4b81;
  border-radius: 4px;
  height: 36px;
}

.rbc-btn-group > button:hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
  border-color: #1f4b81;
  border-radius: 4px;
}

.rbc-btn-group > button.rbc-active {
  background-color: #1f4b81 !important;
  color: white;
}

.rbc-btn-group > button.rbc-active:hover {
  background-color: #1f4b81 !important;
  color: white;
  cursor: pointer;
}

.rbc-time-content {
  border-top: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #dbdbdb;
}

.rbc-header {
  height: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #dbdbdb;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #dbdbdb;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-header.rbc-today {
  color: #1f4b81;
  font-weight: 600;
  position: relative;
}

.rbc-header.rbc-today::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 1px;
  background-color: #1f4b81;
}

.rbc-current-time-indicator {
  background: #ea4335;
  height: 2px;
}

.rbc-current-time-indicator::before {
  content: ' ';
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/************ EVENT STYLES ************/

.rbc-event-label {
  display: none;
}

.rbc-event {
  background-color: transparent;
  border: none !important;
  color: black;
  border-radius: 0;
  padding: 0;
  margin: 0;
  height: 24px;
}

.rbc-addons-dnd-resizable {
  width: calc(100% - 6px);
  height: 100%;
  margin: 3px;
  display: flex;
}

.rbc-event-content {
  flex: 1;
  display: flex;
}

/************ MONTH CALENDAR ************/

.rbc-month-view {
  border: 1px solid #dbdbdb;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #dbdbdb;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #dbdbdb;
}

.rbc-off-range-bg {
  background-color: #eeeeee;
}

.rbc-off-range a {
  color: #9e9e9e;
}

.day-off {
  background: repeating-linear-gradient(-60deg, #eeeeee, #eeeeee 10px, #fafafa 10px, #fafafa 20px);
}

.rbc-row-segment {
  text-align: center;
}

.rbc-show-more {
  text-decoration: none;
  font-weight: 700;
  color: #9e9e9e;
}

.rbc-show-more:hover {
  cursor: pointer;
  text-decoration: underline;
}
